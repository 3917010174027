export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "payOnlineLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online link"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
        "payOnline.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online link error"])},
        "payOnline.error.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your pay online at this time. Please try again later."])},
        "payOnline.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online link success"])},
        "payOnline.success.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your request, you will receive a pay online email soon."])},
        "label.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      },
      "th": {
        "payOnlineLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงค์ชำระเงินออนไลน์"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิก"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกลิงค์"])},
        "payOnline.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์ชำระเงินออนไลน์ไม่ถูกต้อง"])},
        "payOnline.error.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถดำเนินการชำระเงินของคุณทางออนไลน์ได้ในขณะนี้ โปรดลองอีกครั้ง"])},
        "payOnline.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์ชำระเงินออนไลน์สำเร็จ"])},
        "payOnline.success.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอบคุณสำหรับคำขอของคุณ คุณจะได้รับอีเมลชำระเงินออนไลน์ในไม่ช้า"])},
        "label.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])}
      },
      "zh": {
        "payOnlineLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online link"])},
        "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
        "payOnline.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online link error"])},
        "payOnline.error.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process your pay online at this time. Please try again later."])},
        "payOnline.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online link success"])},
        "payOnline.success.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your request, you will receive a pay online email soon."])},
        "label.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      }
    }
  })
}
