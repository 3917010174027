export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "downloadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Quotation PDF"])},
        "paymentFrequencyAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual"])},
        "paymentFrequencyMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])}
      },
      "th": {
        "downloadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกดูใบเสนอราคา(ไฟล์ PDF)"])},
        "paymentFrequencyAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายปี"])},
        "paymentFrequencyMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายเดือน"])}
      },
      "zh": {
        "downloadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Quotation PDF"])},
        "paymentFrequencyAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年付"])},
        "paymentFrequencyMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月付"])}
      }
    }
  })
}
