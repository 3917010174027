<style>
#renewal-pdf .dropdown-toggle {
    white-space: normal;
}
</style>
<template>
    <div v-if="autorenewal == 'No'" class="col-sm-12" id="renewal-pdf">
        <button type="button" class="btn btn-block btn-info px-4 my-3 dropdown-toggle" id="btnPDFDrop" data-bs-toggle="dropdown" aria-expanded="false">{{$t("downloadPDF")}}</button>
        <ul class="dropdown-menu dropdown-menu-end px-2 py-3" aria-labelledby="btnPDFDrop">
            <li><a class="dropdown-item" href="javascript:void(0)" @click="genPDF(Global.locale,payMentFrequency.yearly.value)">{{$t("paymentFrequencyAnnual")}}</a></li>
            <li><a class="dropdown-item" href="javascript:void(0)" @click="genPDF(Global.locale,payMentFrequency.monthly.value)">{{$t("paymentFrequencyMonthly")}}</a></li>
        </ul>
    </div>
    <div v-else class="col-sm-12" id="renewal-pdf">
        <button type="button" class="btn btn-block btn-info px-4 my-3" id="btnPDFDrop" @click="genPDF(Global.locale,'')">{{$t("downloadPDF")}}</button>
    </div>
</template>
<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
export default {
    mixins: [mixin],
    name: 'RenewalDownloadPDF',
    data() {
        return {
            Global,
            payMentFrequency: {
                yearly:{
                    value: "10",
                    text: "Yearly"
                },
                monthly:{
                    value: "30",
                    text: "Monthly no deposit"
                }
            }
        }
    },
    props: {
        opp_name: String,
        autorenewal: String
    },
    methods:{
        genPDF: function(lang,payment){
            var vm = this;
            const locale = lang;
            const agentID = Global.brokerDetails.intermediaryAccountId;
            let url = '';
            if(payment != '')
            {
                const selectPaymentFrequency = payment;
                url = Global.myAccount.frontend + "#/?page=quotationPdf&name=" + encodeURIComponent(vm.opp_name) +"&agentId="+agentID+"&lang="+locale+"&paymentFrequency=" + selectPaymentFrequency;
            }else{
                url = Global.myAccount.frontend + "#/?page=quotationPdf&name=" + encodeURIComponent(vm.opp_name) +"&agentId="+agentID+"&lang="+locale;
            }
            window.open(url);
        },
    }
}
</script>

<i18n>
{
    "en": {
        "downloadPDF": "Get Quotation PDF",
        "paymentFrequencyAnnual": "Annual",
        "paymentFrequencyMonthly": "Monthly",
    },
    "th": {
        "downloadPDF": "คลิกดูใบเสนอราคา(ไฟล์ PDF)",
        "paymentFrequencyAnnual": "รายปี",
        "paymentFrequencyMonthly": "รายเดือน",
    },
    "zh":{
        "downloadPDF": "Get Quotation PDF",
        "paymentFrequencyAnnual": "年付",
        "paymentFrequencyMonthly": "月付",
    }
}
</i18n>