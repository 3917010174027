<style>

</style>

<template>
    <div id="pay-online-link" v-if="link">
        <div class="row">
            <dt class="col-sm-3">{{$t("payOnlineLink")}}</dt>
            <dd class="col-sm-9" style = "padding-left: 2.75em;" v-if="!isRecurring">
                <a
                href="javascript:void(0);"
                :id="'link-pay-online-' + oppname" v-on:click="generatePayOnlineLink(oppname)">
                {{$t("click")}}</a
                >
            </dd>
            <dt class="col-sm-3" v-if="!isRecurring"></dt>
            <dd class="col-sm-9" style = "padding-left: 2.75em;" v-if="!isNull(link)">
                <div class="row">
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="short-link" :value="link" data-selenium-name="short-link"/>
                </div>
                <div class="col-sm-4">
                    <button class="btn btn-sm bg-gradient-primary mx-auto" type="button" v-on:click="copyLinkToClipBoard('short-link')">{{$t('copyLink')}}</button>
                </div>
                </div>
            </dd>
        </div>
    </div>
</template>

<script>
import mixin from "@/components/Mixin";

export default {
    mixins: [mixin],
    name: 'PayOnlineLink',
    props: {
        oppname: String,
        link: String,
        isRecurring: Boolean
    }
}
</script>

<i18n>
    {
        "en": {
            "payOnlineLink": "Pay Online link",
            "click": "Click",
            "copyLink": "Copy link",
            "payOnline.error.title": "Pay Online link error",
            "payOnline.error.desc": "Unable to process your pay online at this time. Please try again later.",
            "payOnline.success.title": "Pay Online link success",
            "payOnline.success.desc": "Thank you for your request, you will receive a pay online email soon.",
            "label.close": "Close"
        },
        "th": {
            "payOnlineLink": "ลิงค์ชำระเงินออนไลน์",
            "click": "คลิก",
            "copyLink": "คัดลอกลิงค์",
            "payOnline.error.title": "ลิงก์ชำระเงินออนไลน์ไม่ถูกต้อง",
            "payOnline.error.desc": "ไม่สามารถดำเนินการชำระเงินของคุณทางออนไลน์ได้ในขณะนี้ โปรดลองอีกครั้ง",
            "payOnline.success.title": "ลิงก์ชำระเงินออนไลน์สำเร็จ",
            "payOnline.success.desc": "ขอบคุณสำหรับคำขอของคุณ คุณจะได้รับอีเมลชำระเงินออนไลน์ในไม่ช้า",
            "label.close": "ปิด"
        },
        "zh": {
            "payOnlineLink": "Pay Online link",
            "click": "点击",
            "copyLink": "Copy link",
            "payOnline.error.title": "Pay Online link error",
            "payOnline.error.desc": "Unable to process your pay online at this time. Please try again later.",
            "payOnline.success.title": "Pay Online link success",
            "payOnline.success.desc": "Thank you for your request, you will receive a pay online email soon.",
            "label.close": "Close"
        },
    }
</i18n>